import React, { Component, PureComponent } from 'react'
import Hero from './Hero'
import NavWrap from './NavWrap'

export default class Header extends Component {

    select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    infiniteScroll = () => {
        let selectHeader = this.select('#header')
        if (window.scrollY > 100) {
            selectHeader.classList.add('header-scrolled')
        } else {
            selectHeader.classList.remove('header-scrolled')
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.infiniteScroll);
    }
    render() {
        return (
            <div>
                <header id="header" className="fixed-top ">
                    <div className="container d-flex align-items-center">
                        <h1 className="logo me-auto">
{/*                             <a href="index.html"
                                className="logo me-auto">
                                <img src={process.env.PUBLIC_URL + '/logoesdi1.ico'}
                                    className="img-fluid" /></a> */}
                            <a href="index.html">Esmat Yousif Digital Incubator</a>

                        </h1>
                        {/* Uncomment below if you prefer to use an image logo */}
                        {/* <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
                        <NavWrap></NavWrap>
                    </div>
                </header>

                <Hero></Hero>
                <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
            </div>
        )
    }
}
