import React from 'react'

export default function AboutUs() {
    return (
        <div>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>About Us</h2>
                    </div>
                    <div>
                        <p>
                            EY is a Digital Incubator starting up in Cairo that will have partnerships and clients in Norway, Switzerland and Germany and aim to
                            develop bussiness globaly.
                            EY-DI works closely with partners and clients as an incubator for the embryo phase of software development and MVPs.
                            Moreover, we support during scale up and sustainable product development phases.
                            The main industries EY-DI work for are modeling and simulation, Risk Analysis and Data Analytics,
                            Fintech industry, but many other. Our mission is to provide high quality modular and extendable software development
                            services that is based on best practice, SOLID principles, clean code and excellent microservice architecture.
                        </p>
                        <p>Our partners and clients are experts in their subject matter and we help them crystalize their innovative ideas from thoughts/value proposition all the way to high quality software in production.  </p>

                    </div>
                    <div className="section-title">
                        <h4>Vision</h4>
                    </div>
                    <div className="row content" >
                        <div className="col-lg-12">
                            <ul>
                                <li><i className="ri-check-double-line" />
                                We aim at being a global technological leader that enable innovative ideas to emerge fast, scalable, and affordable.
                                </li>
                                <li><i className="ri-check-double-line" />We want to become a key player in developing shared economy for
                                 better people of earth experience through our shared economy team.</li>
                                <li><i className="ri-check-double-line" /> We aim for a safer world by helping our clients build smart machine learning, software, 
                                and firmware solutions through our risk management incubator team.</li>
                            </ul>
                        </div>

                    </div>
                    <div className="section-title">
                        <h4>Mission</h4>
                    </div>
                    <div className="row content">
                        <div className="col-lg-12">
                            <ul>
                                <li><i className="ri-check-double-line" />
                                Help our clients and partners build high quality machine learning, software and hardware solutions.
                                </li>
                                <li><i className="ri-check-double-line" /> Develop scalable, modular, and extendable solutions
                                 based on SOLID principles, microservices and best practices.</li>
                                <li><i className="ri-check-double-line" /> Adapt new sustainable technologies fast to provide our clients with state-of-the-art 
                                technologies without compromising sustainability.
                                </li>
                                <li><i className="ri-check-double-line" />Investing in innovative solutions through our incubator partnership program.
                                </li>                              

                            </ul>
                        </div>
                    </div>
                    <div className="section-title">
                        <h4>Values</h4>
                    </div>
                    <div className="row content">
                        <div className="col-lg-12">
                            <ul>
                                <li><i className="ri-check-double-line" />
                                Create a "we", "human centered" and "inclusive for all" work culture.
                                 </li>
                                <li><i className="ri-check-double-line" />
                                We work with high ethics and integrity, authenticity, and transparency on top of our list.
                                </li>
                                <li><i className="ri-check-double-line" /> We believe that there is no trade-off between agility and quality from concept
                                 design through MVP all the way to scaled up solutions in production.
                                </li>
                                <li><i className="ri-check-double-line" /> 
                                We do not compromise on respect between ourselves and towards our customers and partners. 
                                </li>
                                <li><i className="ri-check-double-line" />We take pride in our work, and we are on top of the task.</li>                              
                                <li><i className="ri-check-double-line" />We empower employees</li>  
                            </ul>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}
